import React, { useState } from "react";
import {
  VideoBg,
  FeatureContainer,
  FeatureH1,
  GifBg,
  FeatureSubtitle,
} from "./TestimonialVideoElements";
import devicePhoto from "../../images/ebenger_testimonial.mp4";
import { useTranslation } from "react-i18next";

const ProductTestimonialVideo = () => {

  const { t } = useTranslation();

  return (
    <FeatureContainer id="Features">
      <FeatureH1>{t("testimonials")}</FeatureH1>
      <FeatureSubtitle>{t("testimonialsDesc")}</FeatureSubtitle>

      <VideoBg>
      <GifBg autoPlay muted loop playsInline src={devicePhoto} />
      </VideoBg>
    </FeatureContainer>
  );
};

export default ProductTestimonialVideo;
