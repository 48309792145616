import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const HeroContainer = styled.div`
  background: #01A0C7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 900px;
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: linear-gradient(
    //     180deg,
    //     rgba(0, 0, 0, 0) 0%,
    //     rgba(0, 0, 0, 0.2) 100%
    //   ),
    //   linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, transparent 100%);
    // other values: 0.2, 0.6, and 0.2.
    z-index: 2;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const GifBG = styled.img`
  width: 30%;
  height: auto;
  // -o-object-fit: cover;
  // object-fit: cover;
  // background: #232a34;
  // margin-left:30%;
  // margin-top:15%;
  position: absolute;


`;

export const HeroContent = styled.div`
  top: 340px;
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
// font-family: Neue,sans-serif;
font-weight: ultrabold;
color: #fff;
  // text-shadow: 1px 1px 2px pink, 0 0 1em white, 0 0 0.2em white; 
  font-size: 50px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroH2 = styled.h2`
//  font-family: Neue,sans-serif;
 font-weight: ultrabold;
  color: #fff;
  // text-shadow: 1px 1px 2px pink, 0 0 1em white, 0 0 0.2em white; 
  font-size: 45px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroP = styled.p`
  // font-family: Neue,sans-serif;
  margin-top: 24px;
  color: #fff; //#010606;
  // text-shadow: 0px 0px 0px pink, 0 0 1em white;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HeroButtonContainer = styled.div`
  width:80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 16px; /* Adjust the spacing between HeroP and buttons */
  align-items: flex-end;
`;

export const HeroButton = styled.button`
  width: 100px;
  height: 50px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  margin-top: 20px; /* Adjust the spacing between buttons if needed */

  @media screen and (max-width: 480px) {
    width: 80px; /* Adjust button width for smaller screens if needed */
  }
`;

export const BlackButton = styled.button`
  background-color: black;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 10px;
  width: 11rem;
  height: 3rem;
  margin: 10px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    height: 50px;
    margin: 5px;
  }
`;

export const ColoredButton = styled.button`
  background-color: transparent;
  color: #a7dce9;
  padding: 10px;
  border: 1px solid #a7dce9;
  border-radius: 10px;
  width: 11rem;
  height: 3rem;
  margin: 10px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    height: 50px;
    margin: 5px;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  align-items: center;
  width: 100%; /* Use 100% width of the parent container */
  margin-top: 20px; /* Adjust margin as needed */
`;

export const SocialIconLink = styled.a`
  color: #092E49;
  font-size: 24px;
  text-align: center; /* Center the icon horizontally */
  margin: 0 10px; /* Add margin between icons if needed */
`;
