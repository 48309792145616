import React, { useState } from "react";
import {
    Container,
    Column,
    Title,
    Description,
    ImageColumn,
    Title2,
    imgType2,
    img2
} from "./productSection1";
import devicePhoto from "../../images/seekr.png";
import product_rect1 from "../../images/product_rect1.png";
import product_rect2 from "../../images/product_rest2.png";

import { useTranslation } from "react-i18next";



const ThreeColumnLayout = () => {
    const { t } = useTranslation();

    return (
      <Container>
        <Column>
        {/* <Title2>Hello</Title2> */}
        <img2 src={product_rect1} alt="Image" style={{ marginTop: '20px', marginBottom: '20px', marginLeft:'-100px' }} />

          <Title>Qualities</Title>
          <Description>
          ○ Intuitive interface<br></br> ○ Seamless design<br></br> ○ Lightweight <br></br>○ Compatible with iOS and Android devices.
          </Description>
        </Column>
  
        <ImageColumn>
          <img src={devicePhoto} alt="Image" />
        </ImageColumn>
  
        <Column>
          <Title>Seekr</Title>
          <Description>
          Empowering the visually impaired. Regain independence and explore endless possibilities.
          </Description>
          <img2 src={product_rect2} alt="Image" style={{ marginTop: '20px', marginBottom: '20px' }} />
        </Column>
      </Container>
      
    );
  };
  
  export default ThreeColumnLayout;