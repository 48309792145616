import React, { useState } from "react";
import {
  VideoBg,
  FeatureContainer,
  FeatureH1,
  GifBg,
  FeatureSubtitle,
} from "./AboutUsNewElements";
import devicePhoto from "../../images/how_it_works.mp4";
import { useTranslation } from "react-i18next";

const ProductTopVideo = () => {

  const { t } = useTranslation();

  return (
    <FeatureContainer id="Features">
      <FeatureH1>{t("howDoesItWork")}</FeatureH1>
      <FeatureSubtitle>{t("howDoesItWorksubtitleText")}</FeatureSubtitle>

      <VideoBg>
      <GifBg autoPlay muted loop playsInline src={devicePhoto} />
      </VideoBg>
    </FeatureContainer>
  );
};

export default ProductTopVideo;
