import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  HeroBg,
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroH2,
  HeroP,
  VideoBg,
  HeroButtonContainer,
  HeroButton,
  BlackButton,
  ColoredButton,
  GifBG,
} from "./HeroElements";
import devicePhoto from "../../images/seekr.gif";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate(); // hook for navigation

  const onHover = () => {
    setHover(!hover);
  };

  const { t } = useTranslation();

  const handleLearnMoreClick = () => {
    // Redirect to the 'learn more' page
    navigate("/product");
  };

  const handleJoinWaitlistClick = () => {
    // Redirect to the 'waitlist' page
    navigate("/waitlist");
  };

  
  return (
    <HeroContainer id="home">
      <HeroBg>
        <GifBG src={devicePhoto} />
      </HeroBg>
      <HeroContent>
        <HeroH1>{t("Seekr")}</HeroH1>
        <HeroH2>{t("Your New Visual Companion")}</HeroH2>
        <HeroP>{t("HeroParagraph")}</HeroP>
        <HeroButtonContainer>
          <BlackButton onClick={handleLearnMoreClick}>Learn more</BlackButton>
          <ColoredButton onClick={handleJoinWaitlistClick}>Join the waitlist</ColoredButton>
        </HeroButtonContainer>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;