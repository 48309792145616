import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const AboutUsContainer = styled.div`
  background: white;
  height: 800px;

  @media screen and (max-width: 768px) {
    padding: 50px 0;
    height: 940px;
  }
`;

export const AboutUsWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 100%;
  width: 100%;
//   max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    height: 860px;
  }
`;

export const AboutUsRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto);
  align-items: center;
  grid-template-areas: "col1 col2";

  @media screen and (max-width: 768px) {
    grid-template-areas: "col1 col1" "col2 col2";
  }
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 20px;
  grid-area: col1;
`;

// export const Column2 = styled.div`
//   margin-bottom: 15px;
//   padding: 0 20px;
//   grid-area: col2;
// `;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const AboutUsH1 = styled.h1`
  color: #092E49;
  font-size: 48px;
  text-align: left;
  margin: 40px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    font-size: 35px;
  }
`;

export const AboutUsH3 = styled.h3`
  font-size: 38px;
  text-align: left;
  color: #000000;
  margin-top: 60px;
  @media screen and (max-width: 768px) {
    margin-top: 30px;
    text-align: center;
    font-size: 34px;
  }
`;



export const AboutUsText = styled.p`
  margin-top: 30px;
  font-size: 1rem;
  color: #000000;
  width: 80%;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    text-align: center;
    margin-right: 0;
    width: 100%;
  }
`;



export const CardTitle = styled.h2`
  color: #000000;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;

`;

export const CardSubtitle = styled.h3`
text-align: center;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
`;

export const CardDescription = styled.p`
text-align: center;
  color: #000000;
  font-size: 16px;
`;

export const CardDesignation = styled.h3`
text-align: center;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  padding-bottom:10px;
`;


export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 20px;
  grid-area: col2;
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Two columns
  gap: 30px;

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const Card = styled.div`
  background: white;
  display: flex; /* Make the card a flex container */
  @media screen and (max-width: 7 68px) {
    flex-direction: column;

  }
`;

export const CardImage = styled.img`
  min-width: 200px; /* Set a minimum width */
  width: 20%; /* Set a normal width as a percentage */
  height: auto;
  object-fit: cover;
  border-radius: 4px 0 0 4px;

  @media screen and (max-width: 100%) {
    width: 100%; /* Change to full width on smaller screens */
    border-radius: 4px; /* Adjust border-radius for full width */
  }
`;

export const CardInside = styled.div`
border-radius: 5px;

border: 2px solid #092E49;
  flex: 1; /* Take the remaining space */
  padding: 20px; /* Adjust padding as needed */
`;
export const SocialIcons = styled.div`
  display: flex;
  justify-content: center; /* Center the icons horizontally */
  align-items: center;
  width: 100%; /* Use 100% width of the parent container */
  margin-top: 20px; /* Adjust margin as needed */
`;

export const SocialIconLink = styled.a`
  color: #092E49;
  font-size: 24px;
  text-align: center; /* Center the icon horizontally */
  margin: 0 10px; /* Add margin between icons if needed */
`;


export const SocialLogo = styled(LinkR)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;