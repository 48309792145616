import React, { useState } from "react";
import {
KeyFeatureContainer,
FeatureH1,
CardContainer,
CardImage,
CardTitle,
CardDescription,
ButtonSection,
BlackButton,
ColoredButton,
CardSectionContainer
} from "./HeroSectionAboutUsElements";
import keyImage1 from "../../images/about_us_1.png";
import keyImage2 from "../../images/about_us_2.png";
import keyImage3 from "../../images/about_us_3.png";

import { useTranslation } from "react-i18next";


const HeroSectionAboutUs = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const { t } = useTranslation();

  const cardData = [
    {
      imageUrl: keyImage1,
      title: t("ourVision"),
      description:  t("visionDesc"),
    },
    {
      imageUrl:keyImage2,
      title:t("whatWeDo"),
      description: t("whatWeDoDesc"),
    },
    {
      imageUrl: keyImage3,
      title: t("ourMission"),
      description: t("ourMissionDesc"),
    },
  ];


  return (
    <KeyFeatureContainer >
      <FeatureH1>{t("aboutUs")}</FeatureH1>
      <CardSectionContainer>
        {cardData.map((card, index) => (
          <CardContainer key={index}>
            <CardImage src={card.imageUrl} alt={`Card ${index + 1}`} />
            <CardTitle>{card.title}</CardTitle>
            <CardDescription>{card.description}</CardDescription>
          </CardContainer>
        ))}
      </CardSectionContainer>
     {/* <ButtonSection>
        <BlackButton>Join the Waitlist</BlackButton>
        <ColoredButton>FAQs</ColoredButton>
        <ColoredButton>Learn More</ColoredButton>
      </ButtonSection> */}
    </KeyFeatureContainer>
  );
};

export default HeroSectionAboutUs;


