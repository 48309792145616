import React, { useState } from "react";
import {
  AboutUsContainer,
  AboutUsWrapper,
  AboutUsRow,
  Column2,
  AboutUsH1,
  Card,
  CardTitle,
  CardDescription,
  CardInside,
  CardImage,
  CardDesignation,
  CardSubtitle,
  SocialIcons,
  SocialIconLink,
} from "./TeamUpdatedElements";
import {
  FaFacebook,
  FaEnvelope,
  FaLinkedin,
} from "react-icons/fa";
import Turzo from "../../images/turjo.png";
import Lamia from "../../images/lamia.png";
import Safwan from "../../images/safwan_update.png";
import Reshika from "../../images/reshika_update.png";
import Kousik from "../../images/kousik.png";
import Ahnaf from "../../images/Ahnaf.png";



import { useTranslation } from "react-i18next";

const TeamUpdated = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:info@vidilabs.com.hk";
  };

  const { t } = useTranslation();

  return (
    <AboutUsContainer  id="About">
        <AboutUsWrapper>
        <AboutUsH1>{t("Our Team")}</AboutUsH1>
          <AboutUsRow>
            <Column2>
            {/* Single Card  */}
            <Card>
            <CardImage src={Turzo} alt={"rounded picture"} />
              <CardInside>
                <div>
                  <CardTitle>Turzo Bose</CardTitle>
                  <CardDesignation>CEO</CardDesignation>
                  <SocialIcons>
                  <SocialIconLink
              onClick={handleEmailClick} // Call the handleEmailClick function
              aria-label="Email">
                <FaEnvelope />
            </SocialIconLink>
              <SocialIconLink href="https://www.linkedin.com/in/turzobose/" target="_blank" aria-label="LinkedIn">
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink href="https://www.facebook.com/seekr.hongkong/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
            </SocialIcons>
                </div>
              </CardInside>
            </Card>

             {/* Single Card  */}
             <Card>
            <CardImage src={Lamia} alt={"rounded picture"} />
              <CardInside>
                <div>
                  <CardTitle>Lamia Sreya Rahman</CardTitle>
                  <CardDesignation>COO</CardDesignation>
                  <SocialIcons>
                  <SocialIconLink
              onClick={handleEmailClick} // Call the handleEmailClick function
              aria-label="Email">
                <FaEnvelope />
            </SocialIconLink>
              <SocialIconLink href="https://www.linkedin.com/in/lamiasreya/" target="_blank" aria-label="LinkedIn">
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink href="https://www.facebook.com/seekr.hongkong/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
            </SocialIcons>
                </div>
              </CardInside>
            </Card>

             {/* Single Card  */}
             <Card>
            <CardImage src={Kousik} alt={"rounded picture"} />
              <CardInside>
                <div>
                  <CardTitle>Rezaul Islam Kousik</CardTitle>
                  <CardDesignation>Software Engineer</CardDesignation>
                  <SocialIcons>
                  <SocialIconLink
              onClick={handleEmailClick} // Call the handleEmailClick function
              aria-label="Email">
                <FaEnvelope />
            </SocialIconLink>
              <SocialIconLink href="https://www.linkedin.com/company/seekr-hk/" target="_blank" aria-label="LinkedIn">
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink href="https://www.facebook.com/seekr.hongkong/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
            </SocialIcons>
                </div>
              </CardInside>
            </Card>

             {/* Single Card  */}
             <Card>
            <CardImage src={Safwan} alt={"rounded picture"} />
              <CardInside>
                <div>
                  <CardTitle>Safwan Ahmed</CardTitle>
                  <CardDesignation>Business Analyst</CardDesignation>
                  <SocialIcons>
                  <SocialIconLink
              onClick={handleEmailClick} // Call the handleEmailClick function
              aria-label="Email">
                <FaEnvelope />
            </SocialIconLink>
              <SocialIconLink href="https://www.linkedin.com/in/safwan-ahmed-7688731b6/" target="_blank" aria-label="LinkedIn">
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink href="https://www.facebook.com/seekr.hongkong/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
            </SocialIcons>
                </div>
              </CardInside>
            </Card>
            <Card>
            <CardImage src={Reshika} alt={"rounded picture"} />
              <CardInside>
                <div>
                  <CardTitle>Reshika P V</CardTitle>
                  <CardDesignation>Machine Learning Engineer</CardDesignation>
                  <SocialIcons>
                  <SocialIconLink
              onClick={handleEmailClick} // Call the handleEmailClick function
              aria-label="Email">
                <FaEnvelope />
            </SocialIconLink>
              <SocialIconLink href="https://www.linkedin.com/in/reshika-p-v-b1ab5810a/" target="_blank" aria-label="LinkedIn">
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink href="https://www.facebook.com/seekr.hongkong/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
            </SocialIcons>
                </div>
              </CardInside>
            </Card>

             {/* Single Card  */}
             <Card>
            <CardImage src={Ahnaf} alt={"rounded picture"} />
              <CardInside>
                <div>
                  <CardTitle>Ahnaf Rahat</CardTitle>
                  <CardDesignation>Software Engineer</CardDesignation>
                  <SocialIcons>
                  <SocialIconLink
              onClick={handleEmailClick} // Call the handleEmailClick function
              aria-label="Email">
                <FaEnvelope />
            </SocialIconLink>
              <SocialIconLink href="https://www.linkedin.com/in/ahnafrahat/" target="_blank" aria-label="LinkedIn">
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink href="https://www.facebook.com/seekr.hongkong/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink>
            </SocialIcons>
                </div>
              </CardInside>
            </Card>
            </Column2>
          </AboutUsRow>
        </AboutUsWrapper>
      </AboutUsContainer>
  );
};

export default TeamUpdated;
